import { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

export default function App() {
  const [password, setPassword] = useState("");
  const [contestId, setContestId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit() {
    const pass = window.prompt("password");
    setPassword(pass);
    setIsLoading(true);
    axios
      .post(
        "https://api.student.ccc.training/hackerrank/contest/stats",
        { password: pass, contestId },
        { responseType: "blob" }
      )
      .then((resp) => {
        const filename = "hackerrank_contest.xlsx";
        saveAs(resp.data, filename);
      })
      .catch((err) => {
        window.alert("Error Occured");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleChange(e) {
    const { value } = e.target;
    setContestId(value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <div className="App">
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <div>
          <label>Enter Contest Id</label>
          <input
            name="contestId"
            className="input"
            placeholder="Example: apr-23-ccc-srm-trp-ph-2-coding-practice-open"
          />
        </div>
        <div>
          <button className="button">{isLoading ? "Downloading..." : "Download"}</button>
        </div>
      </form>
    </div>
  );
}
